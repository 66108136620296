import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Helmet } from "react-helmet";
import CtaBanner from "../components/cta-banner";
import Layout from "../components/layout";
import FaqSection from "../components/faq-section";
import TwoColumnContentWithTopImage from "../components/two-column-content-with-top-image";
import TwoColumnImageContent from "../components/two-column-image-content";
import HeroBanner from "../components/hero";
import TwoColumnContentWithBottomImage from "../components/two-column-content-with-bottom-image";
import CertificationSteps from "../components/certification-steps";

const NCZAgilite = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "agilite" }) {
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							sourceUrl
							mediaDetails {
								height
								width
							}
						}
					}
				}
				linaker {
					bannerSectionNew {
						logo {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						backgroundImage {
							node {
								altText
								sourceUrl
							}
						}
						heading
						button {
							title
							url
						}
					}
					certificationSectionNew {
						certificationBody
						certificationSmallHeading
						certificationHeading
						certificationBackground {
							node {
								altText
								sourceUrl
							}
						}
						certificationCta {
							title
							url
						}
						certificationItems {
							certificationItemContent
							certificationItemColor
							certificationItemBackgroundColor
							certificationItemLogo {
								node {
									altText
									sourceUrl
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: WEBP
												quality: 80
												transformOptions: { cropFocus: CENTER, fit: COVER }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
					}
					faqsSectionNew {
						faqsSectionHeading
						faqsSectionContent {
							answerContent
							questionText
						}
					}
					nczBackgroundImageCtaNew {
						sectionHeading
						sectionSubHeading
						sectionButton {
							title
							url
						}
						sectionBackgroundImage {
							node {
								altText
								sourceUrl
							}
						}
					}
					serviceTwoColumnBottomImageNew {
						backgroundColor
						twoColumnContent
						twoColumnHeading
						twoColumnSubHeading
						twoColumnButton1 {
							title
							url
						}
						twoColumnButton2 {
							title
							url
						}
						backgroundImage {
							node {
								altText
								sourceUrl
							}
						}
						fullWidthBottomImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					twoColumnImageContentNew {
						twoColumnContent
						twoColumnBackgroundColor
						twoColumnVideoYoutube
						twoColumnTagline
						twoColumnHeading
						twoColumnCta1 {
							title
							url
						}
						twoColumnCta2 {
							title
							url
						}
						twoColumnImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: WEBP
											quality: 80
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						twoColumnVideo {
							node {
								altText
								sourceUrl
								mediaItemUrl
							}
						}
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		pageData: { seoFields, linaker },
	} = data;

	return (
		<>
			<GatsbySeo title={seoFields?.title} language="en" noindex />

			<Layout navBg={true}>
				<HeroBanner
					newTab
					hasLogo
					logo={
						linaker?.bannerSectionNew.logo.node.localFile.childImageSharp
							.gatsbyImageData
					}
					heading={linaker?.bannerSectionNew.heading}
					hideDesc
					bgImage={linaker?.bannerSectionNew.backgroundImage?.node?.sourceUrl}
					showUnderline={false}
					textAlign={"center"}
					columns={9}
					btnCtaSingle={linaker?.bannerSectionNew.button}
				/>
				<TwoColumnImageContent
					heading={linaker?.twoColumnImageContentNew.twoColumnHeading}
					content={linaker?.twoColumnImageContentNew.twoColumnContent}
					tagline={linaker?.twoColumnImageContentNew.twoColumnTagline}
					btnCta1={linaker?.twoColumnImageContentNew.twoColumnCta1}
					btnCta2={linaker?.twoColumnImageContentNew.twoColumnCta2}
					image={linaker?.twoColumnImageContentNew.twoColumnImage?.node}
					videoUrl={linaker?.twoColumnImageContentNew?.twoColumnVideoYoutube}
					video={linaker?.twoColumnImageContentNew.twoColumnVideo?.node}
					bgColor={linaker?.twoColumnImageContentNew.twoColumnBackgroundColor}
					textColor={linaker?.twoColumnImageContentNew.twoColumnBackgroundColor}
				/>
				<TwoColumnImageContent
					newTab
					heading={linaker?.serviceTwoColumnBottomImageNew.twoColumnHeading}
					content={linaker?.serviceTwoColumnBottomImageNew.twoColumnContent}
					tagline={linaker?.serviceTwoColumnBottomImageNew.twoColumnSubHeading}
					btnCta1={linaker?.serviceTwoColumnBottomImageNew.twoColumnButton1}
					btnCta2={linaker?.serviceTwoColumnBottomImageNew.twoColumnButton2}
					image={
						linaker?.serviceTwoColumnBottomImageNew.fullWidthBottomImage?.node
					}
					bgColor={linaker?.serviceTwoColumnBottomImageNew.backgroundColor}
					flip={true}
					noTopPadding
					textColor={"#36B49F"}
				/>

				<CertificationSteps
					tagline={linaker?.certificationSectionNew.certificationSmallHeading}
					body={linaker?.certificationSectionNew.certificationBody}
					heading={linaker?.certificationSectionNew.certificationHeading}
					bgImage={
						linaker?.certificationSectionNew.certificationBackground?.node
							.sourceUrl
					}
					certificationItems={
						linaker?.certificationSectionNew?.certificationItems
					}
				/>
				<FaqSection
					heading={linaker?.faqsSectionNew.faqsSectionHeading}
					faqData={linaker?.faqsSectionNew.faqsSectionContent}
				/>
				<CtaBanner
					newTab
					heading={linaker?.nczBackgroundImageCtaNew.sectionHeading}
					content={linaker?.nczBackgroundImageCtaNew.sectionSubHeading}
					btnCta1={linaker?.nczBackgroundImageCtaNew.sectionButton}
					bgImage={
						linaker?.nczBackgroundImageCtaNew.sectionBackgroundImage?.node
					}
					textPosition="center"
					isGreen={true}
					column={8}
				/>
			</Layout>
		</>
	);
};

export default NCZAgilite;
